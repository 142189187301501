import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import QuestionDetailsModal from './questionDetailsModal';

const Question = ({ currentQuestion, index }) => {
  //eslint-disable-next-line
  const [boardId, setBoardId] = useState('');
  const [questionViewModal, setQuestionViewModal] = useState(false);

  const questionFilteredData = {
    academicYear: currentQuestion?.academic_year[0] || null,
    grade: currentQuestion?.grade[0]?.grade_name || null,
    subject: currentQuestion?.subject[0]?.subject_name || null,
    board: boardId || null,
    volume: currentQuestion?.volume[0]?.volume_name || null,
    module: currentQuestion?.module[0]?.lt_module_name || null,
    chapter: currentQuestion?.chapter[0]?.chapter_name || null,
    keyConcept: currentQuestion?.key_concept[0]?.topic_name || null,
    questionType: currentQuestion?.question_type_name || null,
    questionMarks: currentQuestion?.question_mark || null,
  };

  const handleViewQuestion = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setQuestionViewModal(true);
  };
  const handleCloseViewQuestion = () => {
    setQuestionViewModal(false);
  };

  return (
    <>
      <div
        className='row p-2 mb-3 th-br-8 th-pointer'
        style={{ outline: '1px solid #f3f3f3' }}
      >
        <div className='col-md-12 px-0 d-flex justify-content-between'>
          <div className='pb-2'>
            <Tag>{questionFilteredData?.questionType}</Tag>
            <span className='th-12'>
              {questionFilteredData?.grade} | {questionFilteredData?.subject} |{' '}
              {questionFilteredData?.volume} | {questionFilteredData?.module} |{' '}
              {questionFilteredData?.chapter} | {questionFilteredData?.keyConcept}
            </span>
          </div>
          <div className='text-right'>
            <Tag
              icon={<EyeOutlined />}
              color='processing'
              onClick={(e) => handleViewQuestion(e)}
              className='th-pointer th-12'
            >
              View Question
            </Tag>
          </div>
        </div>
        <div
          className='col-md-12 pl-0 border-right th-bg-blue-1 th-br-8'
          style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          <div className=' row p-2 '>
            <div className='th-question-box '>
              {ReactHtmlParser(currentQuestion?.question)}
            </div>{' '}
          </div>
        </div>
      </div>
      {questionViewModal && (
        <QuestionDetailsModal
          questionViewModal={questionViewModal}
          handleCloseViewQuestion={handleCloseViewQuestion}
          currentQuestion={currentQuestion}
          index={index}
        />
      )}
    </>
  );
};

export default Question;
